import { Stack, Typography, IconButton } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export const Error: React.FC<{ children?: never }> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const errorMessage = searchParams.get("errorMessage") || "";
    
    return (
        <Stack width="100%" height="calc(100vh - 52px)" gap={12}  sx={{paddingTop: "10px"}}>
            <Stack direction="row"  alignItems="center">
                <Link to="/mobile">
                    <IconButton aria-label="back" sx={{width: "fit-content"}}>
                        <ArrowBackIosRoundedIcon />
                    </IconButton>
                </Link>
                <Typography sx={{fontWeight: 500, lineHeight: 2, textAlign: "justify", fontSize: 15}}>Torna alla Home</Typography>
            </Stack>
            <Stack width="100%" alignItems="center" justifyContent="center">
                <Typography sx={{fontWeight: 300, lineHeight: 2, textAlign: "justify", fontSize: 20}}>{errorMessage}</Typography>
            </Stack>
        </Stack>

    );
}