import React from 'react'
import { BoxProps, Box } from "@mui/material";

export interface IHeadingDividerProps extends BoxProps {
    children?: never
}

export const HeadingDivider: React.FC<IHeadingDividerProps> = (props) => {
  return (
    <Box sx={{backgroundColor: "#00b4ff", height: "2px", width: "100px", marginTop: "-10px"}}></Box>
  )
}
