import {
  Card,
  CardContent,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  styled,
  Box,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import * as CryptoJS from "crypto-js";
import type { ICitizen } from "../types/ICitizen";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import WcIcon from '@mui/icons-material/Wc';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlaceIcon from '@mui/icons-material/Place';
import { Button } from '@mui/material';
import Cookies from 'universal-cookie';

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  maxHeight: 590,
  scrollSnapAlign: "start",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    margin: "0 28px 0 0",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 350,
  },
}));

export const CitizenInfoCard: React.FC<ICitizen> = (props) => {

  const { REACT_APP_NAME } = process.env;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userPayloadCrypted = searchParams.get("pld") || "";

  const cookies = new Cookies();
  cookies.set('pld', userPayloadCrypted);

  const userPayload = JSON.parse(CryptoJS.enc.Base64.parse(userPayloadCrypted!).toString(CryptoJS.enc.Utf8));
  userPayload.fiscalNumber = userPayload.fiscalNumber.slice(userPayload.fiscalNumber.indexOf('-') + 1, userPayload.fiscalNumber.length+1);
  
  return (
    
    <Stack gap={2} sx={{paddingTop: "10px"}}>
      <Stack direction="row"  alignItems="center">
        <IconButton aria-label="back" sx={{width: "fit-content"}} onClick={() => navigate(-4)}>
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Typography sx={{fontWeight: 500, lineHeight: 2, textAlign: "justify", fontSize: 15}}>Torna alla Home</Typography>
      </Stack>
      <Typography sx={{fontWeight: 100, lineHeight: 2, textAlign: "center", fontSize:20}}>
        Per proseguire, controlla che i tuoi dati siano corretti e premi "Conferma".
      </Typography>
      <StyledCard style={{width: "100%"}}>
          <CardContent>
            <Stack spacing={2}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  marginLeft: "-16px !important",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#8686e7",
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Nome"}
                      </Stack>
                    }
                     secondary={userPayload.name}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <PersonOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Cognome"}
                      </Stack>
                    }
                    secondary={userPayload.familyName}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#8686e7",
                      }}
                    >
                      <CreditCardIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Codice Fiscale"}
                      </Stack>
                    }
                     secondary={userPayload.fiscalNumber}
                  />
                </ListItem>                
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <WcIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Genere"}
                      </Stack>
                    }
                     secondary={userPayload.gender === 'F' ? 'Femminile' : 'Maschile'}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#8686e7",
                      }}
                    >
                      <PlaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Luogo di Nascita"}
                      </Stack>
                    }
                     secondary={userPayload.placeOfBirth}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <CalendarTodayIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Data di Nascita"}
                      </Stack>
                    }
                     secondary={userPayload.dateOfBirth}
                  />
                </ListItem>
                {/* <Divider variant="inset" component="li" />
                 <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#8686e7",
                      }}
                    >
                      <PhoneIphoneIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Numero di Telefono"}
                      </Stack>
                    }
                    secondary={userPayload.mobilePhone}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {"Indirizzo Email"}
                      </Stack>
                    }
                     secondary={userPayload.email}
                  />
                </ListItem> */}
              </List>
            </Stack>
          </CardContent>
      </StyledCard>
      <Stack direction="row" gap={10} justifyContent="center">
        <Button onClick={() => navigate(-4)} variant="contained" color="secondary">Annulla</Button>
        <Button href="/back-to-app" variant="contained" color="primary">Conferma</Button>
      </Stack>
    </Stack>
  );
};

export default CitizenInfoCard;
