import {
  Toolbar, Typography
} from "@mui/material";
import SpidAgidLogo from "../assets/spid-agid-logo-bb.png";
import { useLocation } from "react-router-dom";

export const Footer = () => {
  const location = useLocation();
  const isWebView = location.pathname.includes('mobile') || location.pathname.includes('acs');

  

  return (
    <Toolbar sx={{
      boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
      backgroundColor: "#233142",
      minHeight: "52px !important",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: isWebView ? "absolute" : "unset",
      bottom: "0px",
      left: "0px",
      right: "0px"
  }}>
    <Typography sx={{color: "white", fontFamily: "Poppins, sans-serif", textTransform: "uppercase",
    letterSpacing: "4px", lineHeight: 1, fontSize: "12px"}}>Copyright © 2023 Cyberneid</Typography>
        </Toolbar>); 
}