import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const Theme = createTheme({
  typography: {
    fontFamily: [
      "'Overpass'",'Helvetica','Arial', 'Lucida', 'sans-serif'
    ].join(','),
    // font
    h2: {
      color: "#008080",
      fontSize:  "calc(15px + 2.7vw)",
      fontWeight: 900,
      lineHeight: "1.1em",
    },
    body1: {
      color: "#333333",
      fontWeight: 300,
      lineHeight: "1.8em",
      fontSize:  "calc(12px + 0.5vw)",
    },
  },
  palette: {
    primary: {
      light: '#566473',
      main: '#2c3e50',
      dark: '#1e2b38',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9e9eeb',
      main: '#8686e7',
      dark: '#5d5da1',
      contrastText: '#000',
    },
  },
});
