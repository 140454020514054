import "@dej611/spid-react-button/dist/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import CitizenInfo from "./pages/CitizenInfo";
import { Footer } from "./components/Footer";
// import { Header } from "./components/Header";
import {
  Stack
} from "@mui/material";
import "./global.css";

import { Theme } from "./Theme";
import { ThemeProvider } from '@mui/material/styles';
import { Error } from "./pages/Error";
import AppMobile from "./AppMobile";
import {BackToApp} from "./pages/BackToApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Stack gap="40px" height="100vh">
        <BrowserRouter>
          {/* <Header></Header> */}
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/mobile" element={<AppMobile />} />
            <Route path="/acs" element={<CitizenInfo></CitizenInfo>} />
            <Route path="/back-to-app" element={<BackToApp></BackToApp>} />  
            <Route path="/error" element={<Error></Error>} />  
          </Routes>
          <Footer></Footer>    
        </BrowserRouter>
      </Stack>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
