import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";

import { SPIDReactButton } from "@dej611/spid-react-button";
import sielteLogo from "./assets/svgs/idp-logos/spid-idp-sielteid.svg";
import arubaLogo from "./assets/svgs/idp-logos/spid-idp-arubaid.svg";
import infocertLogo from "./assets/svgs/idp-logos/spid-idp-infocertid.svg";
import lepidaLogo from "./assets/svgs/idp-logos/spid-idp-lepidaid.svg";
import namirialLogo from "./assets/svgs/idp-logos/spid-idp-namirialid.svg";
import posteLogo from "./assets/svgs/idp-logos/spid-idp-posteid.svg";
import teamsystemLogo from "./assets/svgs/idp-logos/spid-idp-teamsystemid.svg";
import timLogo from "./assets/svgs/idp-logos/spid-idp-timid.svg";
import etnaLogo from "./assets/svgs/idp-logos/spid-idp-etnaid.svg";

const AppMobile: React.FC<{ children?: never }> = () => {
    const otherProviders = [
      {
        entityID: "https://identity.sieltecloud.it",
        logo: sielteLogo,
        entityName: "Sielte",
      },
      {
        entityID: "https://loginspid.aruba.it",
        logo: arubaLogo,
        entityName: "Aruba ID",
      },
      {
        entityID: "https://identity.infocert.it",
        logo: infocertLogo,
        entityName: "Infocert",
      },
      {
        entityID: "https://posteid.poste.it",
        logo: posteLogo,
        entityName: "Poste ID",
      },
      {
        entityID: "https://login.id.tim.it/affwebservices/public/saml2sso",
        logo: timLogo,
        entityName: "Tim ID",
      },
      {
        entityID: "https://idp.namirialtsp.com/idp",
        logo: namirialLogo,
        entityName: "Namirial ID",
      },
      {
        entityID: "https://id.lepida.it/idp/shibboleth",
        logo: lepidaLogo,
        entityName: "Lepida ID",
      },
      {
        entityID: "https://spid.teamsystem.com/idp",
        logo: teamsystemLogo,
        entityName: "Team System ID",
      },
      {
        entityID: "https://id.eht.eu",
        logo: etnaLogo,
        entityName: "Etna ID",
      },
  ];
  return (
    <Box sx={{paddingTop: "10px"}}>
      <Stack alignItems="center" width="100%">
        <Typography sx={{fontWeight: 100, lineHeight: 2, textAlign: "center", fontSize: 20, padding: '50px 0px'}}>
          Clicca su "Entra con SPID" per generare la tua identità elettronica
        </Typography>
        <SPIDReactButton
          url="https://firmoconspid.it/api/login?entityID={{idp}}"
              // url="http://localhost:4000/login?entityID={{idp}}"
          extraProviders={otherProviders}
          supported={["https://id.eht.eu",
          "https://identity.sieltecloud.it",
            "https://loginspid.aruba.it",
            "https://identity.infocert.it",
            "https://posteid.poste.it",
            "https://login.id.tim.it/affwebservices/public/saml2sso",
            "https://idp.namirialtsp.com/idp",
            "https://id.lepida.it/idp/shibboleth",
            "https://spid.teamsystem.com/idp"
          ]}
              // supported={["https://validator.spid.gov.it", "http://57.128.36.89:8088"]}
          
              // supported={["https://validator.spid.gov.it", "https://spid-testenv2:8088"]}
          size="md"
          type="dropdown"
        />
                          <Typography sx={{fontWeight: 100, lineHeight: 2, textAlign: "justify", fontSize: 15, padding: "20px 20px"}}>
          SPID è il sistema di accesso che consente di utilizzare, con un'identità digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati. Se sei già in possesso di un'identità digitale, accedi con le credenziali del tuo gestore. 
          Se non hai ancora un'identità digitale, richiedila ad uno dei gestori.
            </Typography>
      </Stack>

    </Box>
  );
};

export default AppMobile;
