import { Stack, Typography } from "@mui/material";
import * as React from "react";
import FirmaElettronicaImg from "./assets/landing-page-firmosubito/firma-elettronica.jpg";
import PrivacyImg from "./assets/landing-page-firmosubito/Privacy-Firmo-subito.jpg";
import FormatiStandardImg from "./assets/landing-page-firmosubito/Formati-standard-Firmo-subito.jpg";
import FirmoSubitoImg from "./assets/landing-page-firmosubito/firmo-subito-app-screenshot_.png";
import { HeadingDivider } from "./components/HeadingDivider";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SpidAgidLogo from "./assets/spid-agid-logo-bb.png";
import ScrollButton from "./components/ScrollToTopButton";

const App: React.FC<{ children?: never }> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack gap={4}>
      <ScrollButton />
      <Stack sx={{backgroundColor: "#233142"}} alignItems="center">
        <Stack gap={8} direction={isMobile ? "column": "row"} alignItems="center" justifyContent="center" sx={{backgroundColor: "rgba(28,161,168,0.37)", borderRadius: isMobile ? "15px" : "50px", boxShadow: "0px 2px 18px 0px rgb(0 0 0 / 30%)", margin: isMobile ? "8% 10%" : "2% 10%", padding: isMobile ? "4% 0" : "1.5% 170px"}}>
          <Stack alignItems="center" justifyContent="center" gap={6}>
            <Typography variant="h2" sx={{ color: "#fff", textAlign: "center" }}>
              Con FirmoSubito puoi firmare con SPID!
            </Typography>
            <Typography sx={{ color: "#fff", padding: "0 5%", textAlign: "center" }}>
              Inserisci le tue credenziali e ottieni il tuo certificato di firma elettronica avanzata.
            </Typography>
            <Stack direction={isMobile ? "column": "row"} alignItems="center" justifyContent="space-around" gap={4}>
              <a href="https://play.google.com/store/apps/details?id=com.cyberneid.instasign&amp;hl=it&amp;gl=US" style={{height: "60px"}}><img decoding="async" loading="lazy" src="https://firmosubito.it/wp-content/uploads/2021/10/Home1_GooglePlay.png" height="60" alt="Firmo Subito GooglePlay"></img></a>
              <img decoding="async" loading="lazy" src="https://firmosubito.it/wp-content/uploads/2021/10/appstore-button-black.png" height="60" alt="Firmo Subito appstore "></img>
            </Stack>
             <img style={{width: "80%", height: "auto"}}  src={SpidAgidLogo} alt="Spid Agid Logo"></img>
          </Stack>
          <img src={FirmoSubitoImg} alt="FirmoSubito" style={{width: "calc(150px + 40%)", height: "auto"}}></img>
        </Stack>
      </Stack>
     
      <Stack sx={{ padding: "0 12%", marginTop: "4%" }} gap={12}>
        <Stack direction={isMobile ? "column": "row"} gap={4} alignItems= {isMobile ? "center" : "none"}>
          <img src={FirmaElettronicaImg} alt="Firma elettronica avanzata" style={{ objectFit: "cover", minHeight: "200px", minWidth: "200px", width: "100%", height: "auto", maxHeight: "400px", maxWidth: "400px", boxShadow: "0px 2px 18px 0px rgb(0 0 0 / 30%)", borderRadius: "20px"}}></img>
          <Stack gap={2} sx={{marginTop: "30px"}}>
            <Typography variant="h2">
              Firma Elettronica Avanzata
            </Typography>
            <HeadingDivider />
            <Typography>            
              In pochi semplici click puoi firmare un file PDF o un documento di altro tipo ed inviarlo mediante whatsapp, email o altra app di messaggistica.
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={isMobile ? "column-reverse": "row"} gap={4} alignItems= {isMobile ? "center" : "none"}>
          <Stack gap={2} sx={{marginTop: "30px"}}>
            <Typography variant="h2">
              Formati standard
            </Typography>
            <HeadingDivider />
            <Typography>            
              L’app supporta i formati standard PAdES per i documenti PDF e CAdES p7m per i documenti di altro tipo.
            </Typography>
          </Stack>
          <img src={FormatiStandardImg} alt="Formati Standard" style={{ objectFit: "cover", minHeight: "200px", minWidth: "200px", width: "100%", height: "auto", maxHeight: "400px", maxWidth: "400px", boxShadow: "0px 2px 18px 0px rgb(0 0 0 / 30%)", borderRadius: "20px"}}></img>
        </Stack>

        <Stack direction={isMobile ? "column": "row"} gap={4} alignItems= {isMobile ? "center" : "none"}>
          <img src={PrivacyImg} alt="Privacy" style={{ objectFit: "cover", minHeight: "200px", minWidth: "200px", width: "100%", height: "auto", maxHeight: "400px", maxWidth: "400px", boxShadow: "0px 2px 18px 0px rgb(0 0 0 / 30%)", borderRadius: "20px"}}></img>
          <Stack gap={2} sx={{marginTop: "30px"}}>
            <Typography variant="h2">
              Privacy
            </Typography>
            <HeadingDivider />
            <Typography>            
              L’app non acquisisce alcun dato personale e non conserva i documenti in altro luogo all’infuori del dispositivo mobile dell’utente.
            </Typography>
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  );
};

export default App;
